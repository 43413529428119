import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import PageCalculator from '../components/page-calculator';

const CAHPSCalculator = ({ location }) => (
  <Layout pageTitle="CAHPS Calculator" location={location}>
    <PageCalculator vidId="5f96fa6ec8240e4be7d28eb6" />
  </Layout>
);

CAHPSCalculator.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default CAHPSCalculator;
