import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PageCalculator = ({ vidId }) => {
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    const wrap = document.querySelector('div#calc-wrap');
    const script = document.createElement('script');

    script.src = '//dyv6f9ner1ir9.cloudfront.net/assets/js/nloader.js';
    wrap.appendChild(script);
    setLoaded(true);

    return () => {
      wrap.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (loaded) {
      const wrap = document.querySelector('div#calc-wrap');
      const init = document.createElement('script');

      init.text = `initIframe('${vidId}');`;
      setTimeout(() => {
        wrap.appendChild(init);

        return () => {
          wrap.removeChild(init);
        };
      }, 1000);
    }
  }, [loaded]);

  return (
    <>
      <div id="calc-wrap">
        <div
          className="op-interactive"
          id={vidId}
          data-title="CAHPS Outreach Calculator - No Gate"
          data-url={`https://decisionpointhealth763.outgrow.us/${vidId}?vHeight=1`}
          data-width="100%"
        />
      </div>
    </>
  );
};

PageCalculator.propTypes = {
  vidId: PropTypes.string.isRequired,
};

export default PageCalculator;
